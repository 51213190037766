<template>
  <div class="appform">
    <div class="content">
      <div style="display: flex;width: 100%;align-items: flex-start;">
        <div style="display: flex;flex: 1;">
          <img src="../assets/logo.png" style="width: 140px;height: auto;" />
        </div>
      </div>
      <div class="title1" style="margin-top: -40px;">Global Youth Pulses Registration</div>
      <el-form :model="form" :rules="formRules" ref="form">
        <div class="row">
          <div class="item">
            <el-form-item label="Given Name (Native Language)" prop="givenName">
              <div class="input">
                <el-input v-model="form.givenName" placeholder="Please input"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="Last Name (Native Language)" prop="lastName">
              <div class="input">
                <el-input v-model="form.lastName" placeholder="Please input"></el-input>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <el-form-item label="Given Name (EN)" prop="givenName_pinyin">
              <div class="input">
                <el-input v-model="form.givenName_pinyin" placeholder="Please input"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="Last Name (EN)" prop="lastName_pinyin">
              <div class="input">
                <el-input v-model="form.lastName_pinyin" placeholder="Please input"></el-input>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <el-form-item label="Email" prop="email">
              <div class="input">
                <el-input v-model="form.email" placeholder="Please input"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="High School Graduation" prop="graduation">
              <div class="input">
                <el-select v-model="form.graduation" style="width: 400px;" placeholder="Please select"
                  :popper-append-to-body="false" popper-class="customSelect">
                  <el-option v-for="(year, index) in years" :value="year" :key="index" :label="year"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <el-form-item label="Country/Region" prop="country">
              <div class="input">
                <el-select v-model="form.country" style="width: 400px;" placeholder="Please select"
                  @change="handleCountryChanged">
                  <el-option v-for="(country, index) in countryList" :key="index" :label="country.country_en"
                    :value="country.country_en"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="Province/State" prop="province">
              <div class="input">
                <el-input v-model="form.province" placeholder="Please input"></el-input>
              </div>
            </el-form-item>
          </div>
        </div>

        <div class="row" style="align-items: flex-start">
          <div class="item">
            <el-form-item label="Guardian's Email" prop="student_guardian_first_email">
              <div>
                <div class="input">
                  <el-input v-model="form.student_guardian_first_email" placeholder="Please input"></el-input>
                </div>
              </div>
              <p style="color: #666;font-size: 12px;line-height: 16px;margin-top: 10px;">The guardian's
                email only serves as a backup for communication if necessary.</p>

            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="School Name(EN)" prop="account_id">
              <div>
                <div class="input" style="margin-top: 10px;">
                  <el-select v-model="form.account_id" :multiple="false" filterable clearable="true" remote
                    reserve-keyword placeholder="Please input keyword" :remote-method="remoteMethod" :loading="loading"
                    loading-text="Loading" no-data-text="No Result" style="width: 400px;" @change="handleAccoutChanged"
                    @blur="handleBlurChanged">
                    <el-option v-for="(account, index) in accounts" :key="index"
                      :label="account.account_nameEN ? account.account_nameEN : account.account_name"
                      :value="account._id.$id">
                    </el-option>
                  </el-select>
                </div>
                <p style="color: #666;font-size: 12px;line-height: 16px;margin-top: 10px;">Please enter
                  the school's official name with initial caps of each word.</p>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="terms flex items-center justify-center" style="margin-top: 20px;">
        <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
          <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
            :src="require('../assets/unchecked.png')" />
          <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
            :src="require('../assets/checked.png')" />
        </div>
        <p style="line-height: 20px;"> I agree that EAE may use my provided information to set up a personal account and
          <br> to email me about notifications, reminders, and extracurricular opportunities.</p>
      </div>
      <div class="submit">
        <div class="submitBtn" @click="submit">Submit</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCountries,
  searchAccounts,
  registerStudent
} from '../api/index'
import '../assets/common/font.css'
export default ({
  name: 'index',
  data() {
    return {
      isAgree: false,
      accounts: [],
      loading: false,
      years: [],
      form: {
        project_code: 'GYP',
        account_id: '',
        account_name: '',
        account_nameEN: '',
        province: '',
        country: '',

        lastName: '',
        givenName: '',
        lastName_pinyin: '',
        givenName_pinyin: '',
        graduation: '',
        email: '',

        student_guardian_first_email: ''
      },
      formRules: {
        givenName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        account_nameEN: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        province: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        country: [
          { required: true, message: "Please select", trigger: "change" },
        ],
        lastName_pinyin: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenName_pinyin: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        graduation: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        account_id: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        email: [{ required: true, message: "Please input", trigger: "change" }],
      },
      countryList: [],

      selectCountryEn: ''
    }
  },
  mounted() {
    let current_year = new Date().getFullYear()
    let start_year = current_year
    for (let i = 1; i < 5; i++) {
      let year = start_year + i
      this.years.push(year)
    }
    this.requestCountryList()
  },
  methods: {

    handleCountryChanged() {
      for (let i = 0; i < this.countryList.length; i++) {
        if (this.countryList[i].country_en == this.form.country) {
          this.selectCountryEn = this.countryList[i].country_en
          break
        }
      }
    },

    requestCountryList() {
      getCountries().then((res) => {
        this.countryList = res.data
      })
    },


    handleBlurChanged(e) {
      let that = this
      setTimeout(() => {
        if (!that.form.account_id || that.accounts.length <= 0) {
          that.form.account_id = that.form.account_nameEN
        }
      }, 100)
    },

    handleAccoutChanged(e) {
      for (let i = 0; i < this.accounts.length; i++) {
        let account = this.accounts[i]
        if (account._id.$id == this.form.account_id) {
          this.form.account_nameEN = account.account_nameEN ? account.account_nameEN : account.account_name
          break
        }
      }
    },

    remoteMethod(keyword) {
      if (!keyword) {
        return
      }
      this.form.account_nameEN = keyword
      this.loading = true
      searchAccounts(keyword, this.selectCountryEn).then((res) => {
        this.loading = false
        this.accounts = res.data
      })
    },
    submit() {
      if (!this.isAgree) {
        this.$message.warning("You must agree to the statement.");
        return;
      }
      this.$refs.form.validate((val) => {
        if (val) {
          if (this.form.account_id == this.form.account_nameEN) {
            this.form.account_id = ''
          }
          registerStudent(this.form).then((res) => {
            this.$confirm(
              "Your registration is successful. Please login to the system with the email you entered.",
              "Registration Successful",
              {
                confirmButtonText: "Login",
                type: "success",
                showCancelButton: false,
                customClass: "successConfirm",
              }
            ).then(() => {
              this.$router.replace("/login");
            });
          });
        }
      });
    },
  }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
  display: none;
}

.appform {
  font-family: GravityNormal;
  width: 100vw;
  height: 100vh;
  background-color: var(--c-primary);

  .content {
    overflow-y: auto;
    width: 1000px;
    height: 100vh;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0px 40px 20px;
    background: #fff;
    position: relative;

    .title1 {
      margin-top: 30px;
      font-family: GravityBold;
      color: var(--c-primary);
      font-size: 22px;
      margin-bottom: 50px;
      align-items: center;
      width: 100%;
      font-weight: bold;
      text-align: center;
    }

    .row {
      display: flex;
      justify-content: space-between;

      .item {
        width: 420px;

        .label {
          font-size: 16px;
          font-family: GravityNormal;
          font-weight: normal;
          color: #666666;
        }

        .input {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 18px;

          font-family: GravityNormal;
          font-weight: normal;
          color: #333333;

          img {
            margin-right: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .submit {
    .submitBtn {
      font-family: GravityBold;
      width: 280px;
      height: 50px;
      background: var(--c-primary);
      border-radius: 25px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }

    margin-bottom: 50px;
  }
}
</style>
<style lang="scss">
.appform {
  .el-input__inner {
    border-radius: 6px;
    border-color: var(--c-primary);
    font-family: GravityNormal;
    // border: 2px solid #717171;
    // font-size: 18px;
  }

  .el-form-item__label {
    font-family: GravityNormal;
  }
}

.successConfirm {
  width: 470px;

  .el-message-box__status.el-icon-success {
    top: 11% !important;
  }
}
</style>